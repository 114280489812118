.admin {
  background: url("../../images/background2.jpg");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.adminBox {
  background-color: white;
  width: 70%;
  border-radius: 5%;
}

.adminTitle {
  color: rgba(20, 127, 0, 1);
  font-family: "Arvo", serif;
}

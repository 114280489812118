@font-face {
  font-family: "MiFuente";
  src: url("../../font/Balmoral\ Std\ Regular.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

.buttons {
  text-decoration: none;
  color: #147f00;
  background-color: white;
  border-radius: 5px;
  font-family: "Arvo", serif;
  padding: 5px;
  margin: 0;
}

header {
  background: linear-gradient(
    90deg,
    rgba(1, 102, 43, 1) 0%,
    rgba(1, 102, 43, 1) 50%,
    rgba(20, 127, 0, 1) 100%
  );
  padding: 0.5%;
  text-align: center;
  width: 100%;
}

.logo {
  font-family: "MiFuente";
  font-size: 50pt;
  color: white;
  margin: 0;
}

.selected {
  border: solid 1px white;
  background: rgb(1, 102, 43);
  background: linear-gradient(
    90deg,
    rgba(1, 102, 43, 1) 0%,
    rgba(1, 102, 43, 1) 50%,
    rgba(20, 127, 0, 1) 100%
  );
  color: white;
}

@media (max-width: 765px) {
  header {
    top: 0;
    position: sticky;
    z-index: 1;
  }
}

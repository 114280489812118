@font-face {
  font-family: "MiFuente";
  src: url("../../font/Balmoral\ Std\ Regular.otf") format("opentype");
  font-style: normal;
}

.aboutUs {
  background: url("../../images/background2.jpg");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  padding: 5%;
}

.aboutUsPhrase {
  width: 50%;
  font-family: "MiFuente";
  font-size: 40pt;
  color: #147f00;
}

.inside {
  height: inherit;
  width: 40%;
}

.secondBox {
  margin-top: 3%;
  margin-bottom: 3%;
}

.aboutParagraph {
  font-family: "Arvo", serif;
  font-size: 14pt;
  color: #147f00;
  text-align: center;
}

.white {
  background: linear-gradient(
    210deg,
    transparent 20%,
    white 40%,
    white 75%,
    transparent
  );
  border-radius: 3%;
  padding: 3%;
}

.thirdBox {
  margin-top: 2%;
}

.maminaFront {
  height: 300px;
  width: auto;
  border-radius: 2%;
}

.imageContainer {
  padding-left: 3%;
}

@media (max-width: 900px) {
  .aboutUsPhrase {
    font-size: 34pt;
    width: 65%;
  }
  .aboutParagraph {
    font-size: 12pt;
  }
}

@media (max-width: 765px) {
  .aboutUsPhrase {
    width: 65%;
  }
  .aboutParagraph {
    width: 60%;
  }
  .inside {
    width: 80%;
    height: auto;
  }
  .imageContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 4%;
  }
}

@media (max-width: 1020px) {
  .maminaFront {
    height: 225px;
    width: auto;
  }
}

@media (max-width: 500px) {
  .aboutParagraph {
    font-size: 10pt;
    margin-top: 10%;
    margin-bottom: 10%;
  }
  .aboutUsPhrase {
    font-size: 25pt;
    width: 80%;
  }
}

@media (max-width: 350px) {
  .maminaFront {
    height: 150px;
    width: auto;
  }
}

@font-face {
  font-family: "MiFuente";
  src: url("../../font/Balmoral\ Std\ Regular.otf") format("opentype");
  font-style: normal;
}

.homeSection {
  background: url("../../images/background2.jpg");
  background-size: 100% 100%;
  background-position: 0 -10vh;
  background-repeat: no-repeat;
  box-shadow: inset 0 -35vh 100px 0 #ffffff;
  height: 100vh;
  padding: 3%;
}

.homeText {
  font-family: "MiFuente";
  font-size: 42pt;
  color: #147f00;
  width: 50%;
  text-align: center;
}

@media (max-width: 700px) {
  .homeText {
    font-size: 28pt;
  }
}

@media (max-width: 500px) {
  .homeSection {
    background-size: 100% 100%;
    box-shadow: inset 0 -35vh 100px 0 #ffffff;
    height: 75vh;
  }
}

@media (max-width: 350px) {
  .homeSection {
    background-size: 100% 100%;
    box-shadow: inset 0 -35vh 100px 0 #ffffff;
    height: 75vh;
  }

  .homeText {
    font-size: 24pt;
    height: 30%;
    width: 60%;
  }
}

.instalaciones {
  background: url("../../images/background2.jpg");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  padding: 3%;
}

.instBox {
  background: linear-gradient(
    190deg,
    transparent 10%,
    white 30%,
    white 75%,
    transparent
  );
  border-radius: 3%;
  padding: 3%;
}

.instParagContainer {
  width: 50%;
}

.instParagraph {
  font-family: "Arvo", serif;
  color: #147f00;
  text-align: center;
}

.image {
  height: 70vh;
  width: 30vw;
  border-radius: 5%;
}

.arrow {
  margin-right: 2%;
}

.right {
  rotate: 180deg;
  margin-left: 2%;
}

@media (max-width: 900px) {
  .image {
    height: 60vh;
    width: 50vw;
  }
}

@media (max-width: 700px) {
  .instParagraph {
    font-size: 12pt;
  }
}

@media (max-width: 500px) {
  .instParagraph {
    font-size: 10pt;
    width: 70%;
  }
  .image {
    height: 30vh;
  }
}

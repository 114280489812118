.visualizarImagen {
  backdrop-filter: brightness(5%);
  height: 100vh;
  width: 100vw;
  position: fixed;
  z-index: 2;
}

.visImage {
  height: 70vh;
  width: auto;
  border-radius: 5%;
}

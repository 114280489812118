.footer {
  background: linear-gradient(
    90deg,
    rgba(1, 102, 43, 1) 0%,
    rgba(1, 102, 43, 1) 50%,
    rgba(20, 127, 0, 1) 100%
  );
  color: white;
  padding-top: 0.5%;
}

.icons {
  height: 30px;
  width: auto;
  margin-left: 5px;
}

@media (max-width: 990px) {
  .footer {
    padding-left: 5%;
    padding-bottom: 1%;
  }
}

@media (max-width: 500px) {
  .footerInfo {
    font-size: 11pt;
  }
}

.festejoContainer {
  margin-top: 5%;
}

.festejoParagraph {
  font-family: "Arvo", serif;
  text-align: center;
  color: #147f00;
  font-size: 14pt;
  margin-top: 1%;
  margin-bottom: 2%;
}

.festejoTitles {
  color: rgba(20, 127, 0, 1);
  text-align: center;
}

.arrowFest {
  margin-right: 2%;
}

.rightFest {
  rotate: 180deg;
  margin-left: 2%;
}

.festejoImage {
  height: 50vh;
  width: 30vw;
  border-radius: 5%;
}

@media (max-width: 900px) {
  .festejoParagraph {
    font-size: 12pt;
  }
}

@media (max-width: 750px) {
  .festejoImage {
    height: 45vh;
  }
  .arrowFest {
    height: 10%;
    width: 10%;
  }
}

@media (max-width: 600px) {
  .festejoImage {
    height: 40vh;
  }
  .arrowFest {
    height: 10%;
    width: 10%;
  }
}

@media (max-width: 500px) {
  .festejoTitles {
    font-size: 14pt;
    margin-bottom: 5%;
    margin-top: 5%;
  }
  .festejoParagraph {
    font-size: 10pt;
    width: 70%;
    margin-bottom: 4%;
  }
  .festejoImage {
    height: 30vh;
    width: 50vw;
  }
}

@media (max-width: 400px) {
  .festejoParagraph {
    width: 80%;
  }
  .festejoImage {
    height: 25vh;
  }
}

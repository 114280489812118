.login {
  height: 100vh;
  background: url("../../images/background2.jpg");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.loginModal {
  background-color: white;
  border-radius: 5%;
}

.notFound {
  height: 80vh;
  background: url("../../images/background2.jpg");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  padding: 5%;
}

.notFoundBox {
  background: linear-gradient(
    210deg,
    transparent 10%,
    white 20%,
    white 75%,
    transparent
  );
  border-radius: 3%;
  padding: 3%;
}

.notFoundPhrase {
  font-family: "Arvo", serif;
  text-align: center;
  color: #147f00;
}

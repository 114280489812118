.servicios {
  background: url("../../images/background2.jpg");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  padding: 5%;
}

.servBox {
  background: linear-gradient(
    300deg,
    transparent 10%,
    white 20%,
    white 75%,
    transparent
  );
  border-radius: 3%;
  padding: 3%;
}

.servParagraph {
  font-family: "Arvo", serif;
  text-align: center;
  width: 60%;
  color: #147f00;
  font-size: 14pt;
  margin: 2%;
}

.servTitles {
  color: rgba(20, 127, 0, 1);
  margin-top: 2%;
  margin-bottom: 1%;
}

.servImage {
  height: 70vh;
  width: 40vw;
  border-radius: 5%;
}

.servImageContainer {
  width: 70%;
  margin-top: 2%;
  margin-bottom: 2%;
}

@media (max-width: 900px) {
  .servImage {
    height: 60vh;
    width: 50vw;
  }
  .servParagraph {
    font-size: 12pt;
  }
}

@media (max-width: 750px) {
  .servImage {
    height: 70vh;
    width: 60vw;
    margin: 2%;
  }
  .servImageContainer {
    margin-top: 7%;
    margin-bottom: 7%;
  }

  .arrow {
    height: 10%;
    width: 10%;
  }
}

@media (max-width: 500px) {
  .servTitles {
    font-size: 14pt;
    margin-bottom: 5%;
    margin-top: 5%;
  }
  .servParagraph {
    font-size: 10pt;
    width: 70%;
    margin-bottom: 4%;
  }
  .servicios {
    background-size: 100% 30%;
    background-repeat: repeat;
  }
  .servBox {
    background: linear-gradient(
      300deg,
      transparent 10%,
      white 15%,
      white 90%,
      transparent
    );
  }
  .servImage {
    height: 30vh;
    width: 50vw;
  }
}

@media (max-width: 400px) {
  .servParagraph {
    width: 80%;
  }
}

@media (max-width: 300px) {
  .servImage {
    height: 30vh;
    width: 45vw;
  }
}

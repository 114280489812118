.festejos {
  background: url("../../images/background2.jpg");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  padding: 5%;
  min-height: 80vh;
}

.festejosBox {
  background-color: white;
  width: 70%;
  border-radius: 5%;
  padding: 5%;
  background: linear-gradient(
    300deg,
    transparent 10%,
    white 20%,
    white 75%,
    transparent
  );
}

.celebracionesTitles {
  color: rgba(20, 127, 0, 1);
  text-align: center;
}

@media (max-width: 500px) {
  .celebracionesTitles {
    font-size: 14pt;
    margin-bottom: 5%;
    margin-top: 5%;
  }
  .festejos {
    background-size: 100vw 80vh;
    background-repeat: repeat;
  }
  .festejosBox {
    width: 90%;
  }
}

.menus {
  background: url("../../images/background2.jpg");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  padding: 5%;
}

.menuBox {
  background: linear-gradient(
    300deg,
    transparent 10%,
    white 20%,
    white 75%,
    transparent
  );
  border-radius: 3%;
  padding: 3%;
}

.menuParagraph {
  font-family: "Arvo", serif;
  height: 100%;
  color: #147f00;
  font-size: 13pt;
  margin-top: 2%;
  text-align: center;
  width: 70%;
}

.menuTitles {
  color: rgba(20, 127, 0, 1);
}

.menuSections {
  margin-bottom: 3%;
}

.menuParagraphLists {
  height: 100%;
  color: #147f00;
  margin-top: 2%;
  margin-right: 2%;
  font-size: 13pt;
  width: 70%;
}

.menuLists {
  margin-top: 1%;
  font-family: "Arvo", serif;
}

.menuImage {
  height: 70vh;
  width: 40vw;
  border-radius: 5%;
}

@media (max-width: 900px) {
  .menuImage {
    height: 60vh;
    width: 40vw;
  }
  .menuLists {
    font-size: 12pt;
  }
  .menuParagraph {
    font-size: 12pt;
  }
}

@media (max-width: 750px) {
  .menuImage {
    height: 50vh;
    width: 50vw;
  }
}

@media (max-width: 500px) {
  .menuParagraph {
    font-size: 10pt;
    width: 80%;
  }
  .menuLists {
    font-size: 10pt;
  }
  .menuParagraphLists {
    width: 80%;
    margin-left: 0 !important;
  }
  .menuImage {
    height: 30vh;
  }
  .menuImageContainer {
    margin-bottom: 5%;
    margin-top: 5%;
  }
}
